import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 377.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,377.000000) scale(0.100000,-0.100000)">


<path d="M1300 3084 c-138 -26 -192 -38 -233 -51 -79 -26 -48 -23 58 5 91 23
118 26 305 26 180 1 215 -2 292 -21 86 -22 253 -82 246 -88 -2 -2 -48 11 -103
30 -253 86 -574 88 -830 4 -234 -77 -448 -228 -587 -413 -68 -90 -91 -116 -63
-71 41 67 114 154 195 235 55 55 67 70 34 42 -217 -177 -372 -444 -431 -740
-25 -128 -22 -355 7 -487 27 -124 60 -214 118 -330 178 -357 462 -588 851
-693 88 -23 113 -26 296 -26 178 -1 211 2 295 23 52 13 102 28 110 33 11 7 8
8 -10 3 -159 -39 -223 -47 -365 -48 -101 0 -180 5 -240 17 -92 17 -271 72
-279 85 -3 4 27 -3 67 -16 308 -101 619 -92 911 24 202 80 393 224 518 390 58
77 40 76 -21 -1 -82 -103 -222 -223 -326 -280 -27 -15 -39 -19 -25 -9 186 136
281 223 368 339 28 38 50 75 47 81 -2 7 1 12 7 10 6 -1 13 4 16 12 3 9 0 11
-8 6 -9 -5 -11 -4 -6 4 4 6 11 9 16 6 13 -8 82 134 118 240 32 99 49 178 61
300 7 66 9 71 10 30 5 -183 -70 -432 -179 -601 -43 -65 -50 -85 -23 -63 43 35
-81 -126 -162 -210 -63 -67 -76 -84 -42 -56 105 84 245 273 312 419 158 347
153 742 -15 1092 -185 385 -501 638 -913 729 -70 16 -345 29 -397 19z m432
-97 c439 -115 773 -447 894 -890 18 -66 23 -78 18 -43 -15 124 -110 344 -202
469 -25 34 -79 97 -121 141 -42 43 -66 74 -55 68 36 -19 173 -172 230 -258 74
-111 121 -216 157 -353 28 -108 30 -129 31 -296 1 -192 -10 -272 -59 -415 -27
-80 -76 -192 -82 -187 -2 2 10 34 27 70 34 75 81 229 79 261 -1 11 -5 3 -10
-19 -89 -386 -363 -717 -722 -871 -180 -77 -405 -115 -572 -95 -282 33 -539
156 -735 351 -159 158 -268 342 -329 556 -11 38 -20 62 -20 53 -2 -30 49 -191
84 -265 55 -120 139 -239 248 -352 79 -82 89 -95 43 -57 -122 102 -255 272
-306 390 -123 281 -148 568 -76 847 24 90 94 273 102 265 2 -2 -7 -28 -20 -58
-29 -66 -76 -214 -75 -237 0 -9 11 21 25 68 134 452 507 785 983 875 119 23
340 14 463 -18z m-937 -144 c-103 -68 -205 -154 -275 -230 -160 -175 -279
-422 -310 -643 -17 -123 -30 -172 -24 -90 13 165 53 318 116 445 105 209 281
401 479 524 89 55 99 51 14 -6z"/>
<path d="M2021 2656 c-16 -19 1 -29 56 -33 23 -1 60 -11 81 -22 72 -37 108
-86 138 -186 13 -43 40 -40 36 4 -7 88 -78 183 -166 221 -61 26 -130 34 -145
16z"/>
<path d="M795 2589 c-43 -12 -102 -64 -125 -110 -19 -37 -20 -61 -20 -679 0
-556 2 -646 15 -678 20 -47 50 -78 100 -105 40 -22 47 -22 663 -25 403 -2 636
1 661 8 56 15 116 62 140 112 20 43 21 53 21 686 1 615 0 643 -19 679 -25 49
-55 77 -111 102 -44 20 -62 21 -670 20 -344 0 -638 -5 -655 -10z m1274 -48
c44 -6 60 -14 87 -44 18 -19 38 -54 44 -76 7 -28 10 -232 8 -648 -3 -679 1
-643 -75 -700 l-36 -28 -631 -3 c-347 -1 -641 0 -653 3 -37 9 -91 63 -108 108
-14 37 -15 113 -13 662 3 698 -2 655 81 709 l41 26 601 -1 c330 0 624 -4 654
-8z"/>
<path d="M2030 2485 c0 -16 5 -19 53 -30 22 -4 33 -16 44 -45 19 -48 43 -53
43 -9 0 46 -56 99 -104 99 -26 0 -36 -4 -36 -15z"/>
<path d="M2020 2399 c-10 -18 -9 -25 9 -43 27 -27 56 -12 56 30 0 24 -5 30
-27 32 -19 2 -30 -3 -38 -19z"/>
<path d="M1445 2236 c-61 -15 -138 -54 -184 -93 -54 -46 -40 -77 76 -165 l53
-40 30 26 c77 64 195 56 276 -19 36 -34 54 -32 134 15 101 59 105 84 22 162
-68 65 -118 93 -204 113 -75 17 -135 18 -203 1z m240 -44 c11 -6 36 -18 56
-27 40 -18 129 -101 129 -121 0 -18 -26 -42 -71 -64 -48 -25 -71 -25 -93 -1
-56 62 -194 75 -279 28 l-45 -25 -31 19 c-18 11 -29 24 -26 29 4 6 3 9 -2 8
-14 -5 -52 37 -49 55 2 19 45 67 58 67 5 0 28 9 51 19 23 10 51 21 62 24 34
10 219 1 240 -11z"/>
<path d="M1185 2184 c-94 -49 -130 -147 -89 -242 21 -48 29 -27 15 39 -10 44
-8 56 11 101 24 55 22 56 48 -27 12 -38 77 -118 89 -110 12 7 181 -127 181
-143 0 -5 11 -17 25 -26 14 -9 25 -23 25 -30 0 -7 7 -19 15 -26 8 -7 18 -34
21 -61 8 -58 17 -58 16 0 -1 47 -29 106 -69 142 -15 14 -66 54 -113 89 -149
110 -180 148 -180 215 0 41 26 71 67 81 48 10 49 12 8 13 -22 1 -53 -6 -70
-15z"/>
<path d="M1430 1888 c128 -123 175 -154 78 -51 -29 30 -66 62 -83 70 l-30 15
35 -34z"/>
<path d="M1106 1884 c-3 -9 -6 -37 -6 -64 0 -44 2 -48 28 -55 30 -7 132 -80
132 -95 0 -4 2 -15 6 -23 3 -9 -6 -19 -26 -27 -66 -27 -78 -141 -15 -145 6 -1
9 -7 7 -14 -1 -7 2 -10 8 -6 5 3 10 2 10 -4 0 -5 10 -13 23 -17 12 -4 39 -18
60 -31 20 -13 63 -30 94 -38 160 -42 338 7 444 122 63 69 52 98 -62 161 -31
18 -61 32 -67 32 -6 0 -26 -13 -44 -29 -52 -45 -95 -62 -154 -62 -60 0 -126
31 -161 74 -10 13 -61 55 -113 93 -52 39 -109 87 -127 107 -24 28 -33 32 -37
21z m222 -208 c20 -14 47 -36 59 -49 55 -60 164 -79 255 -46 28 10 60 28 71
39 23 26 56 21 104 -16 17 -13 36 -24 42 -24 6 0 11 -7 11 -16 0 -81 -182
-182 -330 -184 -50 0 -158 28 -209 54 -48 25 -111 86 -111 107 0 10 14 34 32
53 35 38 42 60 27 88 -14 25 3 23 49 -6z"/>
<path d="M1041 1734 c-27 -35 -28 -122 -2 -181 23 -51 87 -115 140 -142 49
-26 123 -43 134 -32 5 5 -13 12 -41 16 -65 9 -107 35 -132 85 -12 23 -26 40
-33 37 -17 -5 -70 80 -64 103 3 10 1 20 -3 22 -12 4 16 81 35 94 19 14 19 24
1 24 -8 0 -24 -12 -35 -26z"/>
<path d="M1566 1742 c5 -19 11 -68 13 -115 1 -5 6 -5 12 -1 14 8 4 77 -17 113
-13 23 -14 24 -8 3z"/>
<path d="M967 833 c-15 -25 -26 -51 -24 -57 3 -10 51 57 64 90 12 31 -14 10
-40 -33z"/>
<path d="M1031 866 c-17 -21 -7 -46 19 -46 11 0 20 -5 20 -11 0 -7 -10 -9 -26
-5 -19 5 -25 3 -22 -6 3 -7 18 -13 34 -13 39 0 45 30 10 46 -31 14 -35 32 -6
27 10 -2 20 2 22 7 5 17 -38 17 -51 1z"/>
<path d="M1174 864 c3 -9 6 -31 6 -50 0 -31 3 -34 29 -34 21 0 32 6 36 20 8
25 -10 50 -36 50 -12 0 -16 5 -13 15 4 10 0 15 -11 15 -12 0 -15 -5 -11 -16z"/>
<path d="M1272 868 c-16 -16 -15 -63 2 -78 18 -14 58 -10 63 7 3 8 -6 11 -24
10 -24 -2 -28 2 -28 23 0 20 4 25 23 22 15 -1 22 2 20 10 -6 18 -41 21 -56 6z"/>
<path d="M1350 830 c0 -27 5 -50 10 -50 6 0 10 11 10 25 0 16 6 25 15 25 9 0
15 -9 15 -25 0 -14 5 -25 10 -25 11 0 13 30 4 54 -3 9 -15 16 -25 16 -10 0
-19 7 -19 15 0 8 -4 15 -10 15 -5 0 -10 -22 -10 -50z"/>
<path d="M1102 818 c2 -28 8 -34 31 -36 25 -3 27 -1 26 35 -2 30 -3 32 -6 11
-5 -35 -33 -37 -33 -3 0 14 -5 25 -11 25 -6 0 -9 -14 -7 -32z"/>
<path d="M1430 815 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35 -5
0 -10 -16 -10 -35z"/>
<path d="M1501 832 c-12 -23 4 -52 29 -52 26 0 25 16 -1 23 -25 7 -16 27 12
27 11 0 17 5 14 10 -10 16 -44 11 -54 -8z"/>
<path d="M1571 832 c-13 -25 8 -56 35 -50 29 5 27 62 -3 66 -13 2 -26 -4 -32
-16z"/>
<path d="M1648 813 c-3 -22 0 -33 9 -33 7 0 12 9 10 21 -1 12 4 24 12 27 10 3
13 -2 9 -22 -4 -18 -1 -26 9 -26 9 0 13 8 11 20 -2 10 2 18 7 17 6 -1 10 -10
9 -19 0 -10 5 -18 13 -18 17 0 17 46 1 57 -7 4 -29 8 -50 9 -34 0 -36 -2 -40
-33z"/>
<path d="M1790 816 c0 -20 5 -36 10 -36 6 0 10 11 10 25 0 14 5 25 10 25 6 0
10 -11 10 -25 0 -14 5 -25 11 -25 6 0 9 14 7 33 -2 27 -8 33 -30 35 -26 3 -28
1 -28 -32z"/>
<path d="M1869 834 c-16 -19 0 -50 25 -48 9 1 16 -2 16 -7 0 -5 -9 -9 -20 -9
-11 0 -20 -5 -20 -11 0 -6 12 -9 28 -7 25 3 27 7 30 51 3 44 2 47 -21 47 -14
0 -30 -7 -38 -16z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
